var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading_data_table,"headers":_vm.headers,"items":_vm.desserts,"sort-by":"corporate_name","pageCount":_vm.numberOfPagesPaginate,"page":_vm.currentPagePaginate,"server-items-length":_vm.totalStagesPaginate,"options":_vm.options,"footer-props":{
      itemsPerPageText: 'Itens por pagina',
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Empreiteiros/Medições")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.loadingRefresh,"loading":_vm.loadingRefresh,"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.hitoricMetering()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-list-box ")])],1)]}}])},[_c('span',[_vm._v("Histórico")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-8",attrs:{"append-icon":"mdi-magnify","label":"Localizar","single-line":"","hide-details":""},on:{"click:append":function($event){return _vm.searchItems()},"change":function($event){return _vm.searchItems()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [(_vm.ifContractActual(item))?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v("Aprovado")]):_vm._e(),(!_vm.ifContractActual(item))?_c('v-chip',{attrs:{"small":"","color":"warning"}},[_vm._v("Não Aprovado")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.showHistoric(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-list-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Histórico")])]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showSummary(item)}}},[_vm._v(" mdi-eye ")])]}},{key:"item.cnpj",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCNPJ(item.cnpj))+" ")]}},{key:"item.cpf",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCPF(item.cpf))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" Nenhum registro encontrado ")]},proxy:true}])}),_c('DialogContractorSummary',{attrs:{"contractor":_vm.contractorSummary},on:{"refreshTable":function($event){return _vm.initialize()}},model:{value:(_vm.dialogContractorSummary),callback:function ($$v) {_vm.dialogContractorSummary=$$v},expression:"dialogContractorSummary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }